<template>
  <section class="sobre">
    <h2>Sobre Nos</h2>
    <ul>
      <li><img src="@/assets/img01.jpeg" alt="Sobre Nos" width="400" height="400" title="Sobre Nos" class="img-quem">
      </li>
      <li>
        <p>A Campinas Redes de Proteção se destaca no mercado por fornecer as melhores redes de proteção disponíveis.
          Nossas redes de proteção atendem às necessidades de segurança do cliente de maneira eficiente e qualificada,
          sem comprometer a estética do ambiente, já que não bloqueiam a vista. Estamos comprometidos em oferecer
          produtos de alta qualidade e confiabilidade, para garantir a segurança e tranquilidade dos nossos clientes.
          Trabalhamos com materiais de primeira linha e uma equipe de profissionais altamente treinados para instalar
          as redes de proteção de forma rápida e eficiente. Escolha a Campinas Redes de Proteção para proteger sua
          família e seus animais de estimação com segurança e confiança.</p>
        <p>Se você ainda não conhece a nossa empresa, convidamos você a explorar as diversas oportunidades que
          oferecemos em redes de proteção. Temos uma ampla variedade de produtos de alta qualidade, projetados para
          atender às necessidades de segurança do cliente. Além disso, nossa equipe de profissionais altamente
          capacitados está pronta para ajudá-lo a encontrar a solução perfeita para suas necessidades. Oferecemos uma
          experiência de compra excepcional, com atendimento personalizado e suporte completo antes, durante e após a
          instalação. Não perca mais tempo e confira nossas opções de redes de proteção agora mesmo!</p>
      </li>
    </ul>
    <ul>
      <li>
        <h3 class="txt-normal">Por que instalar Redes de Proteção?</h3>
        <p>As redes de proteção são fundamentais para evitar acidentes em altura, que podem colocar em risco a
          segurança de crianças, animais de estimação e até mesmo empregadas domésticas. É por isso que investir em
          redes de proteção é uma escolha inteligente para garantir a segurança de todos no ambiente. Na Campinas
          Redes, não nos limitamos apenas à instalação das redes, mas também nos preocupamos com a segurança dos
          nossos clientes e seus entes queridos. Trabalhamos com agilidade tanto no processo de orçamento quanto na
          instalação de redes de proteção, varais e outros produtos que oferecemos, sempre com foco na qualidade e
          excelência do nosso serviço.
          Utilizamos apenas materiais de qualidade, que atendem às normas de segurança e com garantia dos nossos
          produtos. Além disso, a Campinas Redes de Proteção oferece soluções personalizadas para atender às
          necessidades específicas de cada cliente. Não comprometa a segurança da sua família e entre em contato
          conosco para saber mais sobre nossas redes de proteção e outros produtos que oferecemos.</p>
      </li>
      <li><img src="@/assets/img02.jpeg" alt="Sobre Nos" title="Sobre Nos" width="400" height="400"
          class="img-quem float-right pl-4"></li>
    </ul>
  </section>
    <section>
      <div>
        <h3 class="txt-normal">
          <span>
            Missão </span>
        </h3>
        <p>
          Nossa missão é assegurar a segurança e a tranquilidade de nossos clientes e seus entes queridos, por meio de
          soluções completas e eficientes em proteção e segurança para janelas, sacadas, varandas, piscinas, quadras
          esportivas e outros ambientes que necessitam de proteção contra quedas e acidentes.</p>
      </div>
      <div>
        <h3 class="txt-normal">
          <span>
            Visão </span>
        </h3>
        <p>
          Nosso objetivo é nos tornarmos referência no mercado de redes de proteção, sendo reconhecidos pela alta
          qualidade de nossos produtos e serviços, bem como pela ética e transparência em nossos relacionamentos com
          clientes e parceiros. Além disso, comprometemo-nos com a responsabilidade social em todas as nossas ações.
        </p>
      </div>
      <div>
        <h3 class="txt-normal">
          <span>
            Valores </span>
        </h3>
        <p>
          Nós valorizamos o comprometimento com a segurança e tranquilidade dos nossos clientes, agindo com ética e
          transparência em todos os nossos relacionamentos. Além disso, estamos empenhados em fornecer produtos e
          serviços da mais alta qualidade, ao mesmo tempo em que respeitamos o meio ambiente e a comunidade. Buscamos o
          aperfeiçoamento contínuo e a inovação em nossos processos e serviços, e valorizamos e respeitamos nossos
          colaboradores como parte essencial do sucesso de nossa empresa. </p>
      </div>
    </section>

</template>
<script>
export default {
  name: 'About'
}
</script>
<style lang="scss" scoped>
section{
  margin:7rem 0!important;
}
.sobre {
  ul {
    margin: 0 !important;
    padding: 0 !important;
    display: grid;
    grid-template-columns: repeat(2, calc((100% - 40px)/2));
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    @media only screen and (max-width: 600px) {
      max-width: 100% !important;
      grid-column-gap: 0;
      grid-row-gap: 0;
      grid-template-columns: repeat(1, 100%)
    }
  }
}

.about {
  font-family: Arial, sans-serif;
  text-align: justify;

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: circle;
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
}</style>
